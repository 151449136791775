import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import Container from 'react-bootstrap/Container';

const FirstSectionContainer = styled(Container)`
  min-height: 334px;
  border-bottom: 2px solid ${props => props.theme.colors.gray_border};
  @media(max-width:767px){
    padding-bottom:30px;
  }
`

const FirstSectionTitle = styled.div`
  color : ${props => props.theme.colors.gray_dark};
  margin-top:61px;
  font: 56px/62px Abril Fatface;
  text-align:center;
  margin-bottom:20px;
  @media(max-width:767px){
    font: 2.5rem/3rem Abril Fatface;
    margin-top:20px;
    margin-bottom:15px;
  }
`

const SectionCol = styled(Col)`
   font: 28px/36px Roboto;
   text-align:center;
   margin-bottom:50px;
   @media(max-width:767px){
    margin-bottom:10px;
   }
`

const SectionImage = styled.img`
  margin-top: 10px;
  margin-bottom:12px;
  @media (max-width: 992px) {
    width: 100%;
  }
`
const ItemContent = styled.div`
  font: 20px/28px Roboto;
  color: ${props => props.theme.colors.gray_dark};
`

export default function Home() {
  return (      
    <FirstSectionContainer>
      <Row className="justify-content-center">
        <Col md="12">
          <FirstSectionTitle>
            Find the perfect <span css={css`color : #30A987;`}>career-friendship balance</span> with fellow WomenWorks mentors!
          </FirstSectionTitle>
        </Col>
      </Row>
      <Row>
        <SectionCol md="4" xs="12">
          <SectionImage src="/page-the-ladybosses-section1-img1.png"/> 
          <ItemContent>As a WomenWorks mentor, you can <b>broaden your social circle through</b> friendly casual connections with other mentors!</ItemContent>
        </SectionCol>
        <SectionCol md="4" xs="12">
          <SectionImage src="/page-the-ladybosses-section1-img2.png"/> 
          <ItemContent>Search for like-minded mentors on our Ladybosses page based on hobbies and interest, and find someone you think you’d vibe with!</ItemContent>
        </SectionCol>
        <SectionCol md="4" xs="12">
          <SectionImage src="/page-the-ladybosses-section1-img3.png"/> 
          <ItemContent>Click on ‘Get connected’ button and we’ll connect you both so you can get to know each other over coffee, lunch, drinks, or Zoom call!</ItemContent>
        </SectionCol>
      </Row>
    </FirstSectionContainer>
  )
}