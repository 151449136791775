import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import Container from "react-bootstrap/Container";
import { OutboundLink } from "gatsby-plugin-google-analytics";

const TheLadyBossesSection3Background = styled.div`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.gray_dark};
  font: 20px/28px Roboto;
  margin-bottom: 100px;
  @media(max-width:768px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`

const TheLadyBossesSection3Title = styled.div`
  font: 58px/62px Abril Fatface;
  margin-bottom:35px;
  color: ${props => props.theme.colors.gray_dark};
  text-align:left;
  @media(max-width:1199px){
    font: 56px/60px Abril Fatface;
  }
  @media(max-width:767px){
    font: 2.5rem/3rem Abril Fatface;
    margin-bottom:17.5px;
  }
`

const TextContent = styled.div`
  font: 20px/22px Roboto;
  color: ${props => props.theme.colors.gray_dark};
  margin-bottom: 32px;
  @media(min-width:991px) {
    max-width : 554px;
    }
`

const ButtonFill = styled(Button)`
  height: 60px;
  color: ${props => props.theme.buttons.primary_button_violet.normal.color} !important;
  background-color: ${props => props.theme.buttons.primary_button_violet.normal.background_color} !important;
  border-color: ${props => props.theme.buttons.primary_button_violet.normal.border_color} !important;
  font: 22px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.theme.buttons.primary_button_violet.hovered.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.hovered.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_violet.hovered.border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.buttons.primary_button_violet.hovered.shadow} !important;
    @media(min-width:1199px){
      margin-top:-3px;
      margin-bottom:3px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.primary_button_violet.pressed.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_violet.pressed.border_color} !important;
    box-shadow: none !important;
    @media(min-width:1199px){
      margin-top:-3px;
      margin-bottom:3px;
    }
  }
  @media(max-width:921px){
    width:100%;
  }
  @media(max-width:767px){
    width:100%;
  }
`

export default function TheLadyBossesSection3(props) {
  return (
  <TheLadyBossesSection3Background backgroundColor={props.Background} color={props.TextColor}>
      <Container>
        <Row>
            <Col xs="12" lg="6">
            <TheLadyBossesSection3Title>
            Not a mentor yet? Let’s get in touch!
            </TheLadyBossesSection3Title> 
            </Col>
            <Col xs="12" lg="6" >
                <Row css={css`margin-left: auto;
    margin-right: auto;`}>
                    <TextContent>Join as a mentor to get WomenWorks circle of professional connections and friendship. You’ll also get to attend our exclusive events and empower others and help close the gender gap that is still prevalent in Indonesia’s workforce today!</TextContent>
                </Row>
                <Row>
                    <Col>
                    <OutboundLink href="https://docs.google.com/forms/d/e/1FAIpQLSctevUzlEqk2C0HzJXKA_BPxnn5qYm9fS38oOHDl2cI0x4Bbg/viewform" target="_blank" rel="noreferrer">
                    <ButtonFill>
                    Register as mentor in 5 minutes!
                    </ButtonFill>
                </OutboundLink>
                    </Col>

                </Row>
            </Col>
        </Row>
        <Row>
                <Col xs="12">
                    <img alt="WomenWorks - ladybosses illustration" src="/page-masterclass-section1-img1.png" 
                        css={css`
                        @media(max-width:991px) {
                            display:none;
                        }
                        @media(min-width:992px) {
                            max-height: 385px;
                            max-width: calc(1212/1440*100vw);
                            object-fit: contain;
                        }
                    `}/>
                </Col>
            </Row>
      </Container>
  </TheLadyBossesSection3Background>
  )
}

