import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import Container from "react-bootstrap/Container";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'

const TheLadyBossesSection4Background = styled.div`
  background-color: ${props => props.theme.colors.white_dark};
  color: ${props => props.theme.colors.gray_dark};
  font: 20px/28px Roboto;
  min-height: 855px;
  position:relative;
  @media(max-width:768px) {
    min-height: 0;
    overflow:hidden;
    padding-top: 50px;
    padding-bottom: 50px;
  }
`

const TheLadyBossesSection4Title = styled.div`
  font: 58px/62px Abril Fatface;
  margin-bottom:35px;
  min-height: 132px;
  color: ${props => props.theme.colors.gray_dark};
  @media(max-width:1199px){
    font: 56px/60px Abril Fatface;
  }
  @media(min-width:1024px) {
    margin-top:71px;
  }
  @media(max-width:767px){
    font: 2.5rem/3rem Abril Fatface;
    margin-bottom:17.5px;
  }
`

const SectionItem = styled.div`
  margin-top:61px;
  margin-bottom:100px;
  display: inline-block;
  @media(max-width:767px){
    margin-top:0;
    margin-bottom:0px;
  }
`

const ButtonFill6 = styled(Button)`
  height: 60px;
  color: ${props => props.theme.buttons.primary_button_violet.normal.color} !important;
  background-color: ${props => props.theme.buttons.primary_button_violet.normal.background_color} !important;
  border-color: ${props => props.theme.buttons.primary_button_violet.normal.border_color} !important;
  font: 22px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.theme.buttons.primary_button_violet.hovered.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.hovered.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_violet.hovered.border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.buttons.primary_button_violet.hovered.shadow} !important;
    @media(min-width:1199px){
      margin-top:-3px;
      margin-bottom:3px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.primary_button_violet.pressed.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_violet.pressed.border_color} !important;
    box-shadow: none !important;
    @media(min-width:1199px){
      margin-top:-3px;
      margin-bottom:3px;
    }
  }
  @media(max-width:921px){
    width:100%;
    margin-bottom:20px;
  }
  @media(max-width:767px){
    width:100%;
  }
`

const BgCol5 = styled(Col)`
  @media(max-width:1199px){
    :after {
      top: 435px;
      right:50px;
    }
  }
  @media(max-width:991px){
    :after {
      display:none;
    }
    :before {
      display:none;
    }
  }
  @media(max-width:767px){
    :before {
      display:none;
    }
    :after {
      display:block;
      width: 300px;
      height: 300px;
      top: -415px;
      left: 73px;
      background-position:center;
    }
  }
  @media(max-width:374px){
    :after {
      width: 280px;
    }
  }
  @media(max-width:359px){
    :after {
      width: 240px;
    }
  }
`
const BulletCol = styled(Col)`
.row {
  margin-bottom:20px;
}`

const FAHeart = styled(FontAwesomeIcon)`
  font-size:1rem;
  color: ${props => props.theme.colors.accent_yellow_hover};
`

const TheLadyBossesSection5ItemList = [
    "Free coaching session with top-notch leaders for every 1-on-1 mentorship session you do",
    "Exclusively facilitated intros to a curated networking pool of Indonesian women leaders of your choosing",
    "Connect to our Insider Job Trade - a marketplace for opportunities in our community",
    "We’re not all about work - You’ll also get 1 free item from the top closets and wardrobe of Indonesian women leaders!"
]

export default function TheLadyBossesSection4(props) {
  return (
  <TheLadyBossesSection4Background>
      <img alt="WomenWorks - two young woman working together" src="/page-the-ladybosses-section4-img1.png" 
        css={css`
          z-index:2;
          @media(max-width:991px) {
            display:none;
          }
          @media(min-width:992px) {
            position: absolute;
            right: 0;
            top:0px;
            height: 100%;
            max-width: 516px;
            object-fit: cover;
          }
          @media(min-width:1600px){
            display:none;
          }
      `}></img>
      <Container>
          <SectionItem>
            <Row>
              <Col>
            <BgCol5></BgCol5>
            <Row>
              <Col xs="12" lg="6">
                <TheLadyBossesSection4Title>
                  As a mentor you’ll get access to:
                </TheLadyBossesSection4Title>
              </Col>
              <Col xs="12" lg="4">
                <img alt="WomenWorks - two young woman working together" src="/page-the-ladybosses-section4-img1.png"
                  css={css`
                    @media(max-width:767px) {
                      width:100%;
                      margin-bottom:15px;
                    }
                    @media(min-width:768px) {
                      object-fit: cover;
                      height: 250px;
                      width:100%;
                      margin-bottom:30px;
                      object-position: 10% 10%;
                    }
                    @media(min-width:992px) {
                      display:none;
                    }
                `}></img>
              </Col>
              <Col xs="12" lg="6" css={css`margin-bottom:25px;@media(max-width:767px){margin-bottom:20px;}`}>
                <Row>
                  <BulletCol>
                      {TheLadyBossesSection5ItemList.map(item =>(
                        <Row>
                            <Col xs="auto" css={css`padding-right:0;`}>
                                <FAHeart icon={faHeart} />
                            </Col>
                            <Col css={css`padding-left:7px;`}>{item}</Col>
                        </Row>
                      ))}
                  </BulletCol>
                </Row>
                <Row>
                  <Col>
                    <OutboundLink href="https://docs.google.com/forms/d/e/1FAIpQLSctevUzlEqk2C0HzJXKA_BPxnn5qYm9fS38oOHDl2cI0x4Bbg/viewform" target="_blank" rel="noreferrer">
                      <ButtonFill6>Become a mentor now</ButtonFill6>
                    </OutboundLink>
                  </Col>
                </Row>
              </Col>
            </Row>
            </Col>
            <Col xl="3" css={css`
                    @media(max-width:1599px) {
                      display:none;
                    }
                `}>
                <img alt="WomenWorks - women working together" src="/page-the-ladybosses-section4-img1.png"
                  css={css`
                    @media(min-width:1600px) {
                        object-fit: contain;
                        height: 100%;
                    }
                `}></img>
            </Col>
            </Row>
          </SectionItem>
      </Container>
  </TheLadyBossesSection4Background>
  )
}

